import { useContext, useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import type { BenefitInfo } from '../../../context/AddMemberContext'
import { AddMemberContext } from '../../../context/AddMemberContext'
import { AddMemberProgressContext } from '../../../context/AddMemberProgressContext'

import { BenefitListCard } from './BenefitListCard'

const benefitCategories: BenefitInfo[] = [
  {
    id: 0,
    name: 'Accident',
    volumeBased: false,
    tiers: [
      {
        tier: 'EMPLOYEE',
        coverages: [],
        covers: ['EMPLOYEE'],
      },
      {
        tier: 'EMPLOYEE_SPOUSE', // TODO: Change this to mactch backend BenefitTier Enum
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE'],
      },
      {
        tier: 'EMPLOYEE_CHILD',
        coverages: [],
        covers: ['EMPLOYEE', 'CHILDREN'],
      },
      {
        tier: 'EMPLOYEE_FAMILY',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
      },
    ],
    waived: false,
    elected: false,
  },
  {
    id: 1,
    name: 'Cancer',
    volumeBased: false,
    tiers: [
      {
        tier: 'EMPLOYEE',
        coverages: [],
        covers: ['EMPLOYEE'],
      },
      {
        tier: 'EMPLOYEE_SPOUSE',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE'],
      },
      {
        tier: 'EMPLOYEE_CHILD',
        coverages: [],
        covers: ['EMPLOYEE', 'CHILDREN'],
      },
      {
        tier: 'EMPLOYEE_FAMILY',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
      },
    ],
    waived: false,
    elected: false,
  },
  {
    id: 2,
    name: 'Dental',
    volumeBased: false,
    tiers: [
      {
        tier: 'EMPLOYEE',
        coverages: [],
        covers: ['EMPLOYEE'],
      },
      {
        tier: 'EMPLOYEE_SPOUSE',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE'],
      },
      {
        tier: 'EMPLOYEE_CHILD',
        coverages: [],
        covers: ['EMPLOYEE', 'CHILDREN'],
      },
      {
        tier: 'EMPLOYEE_FAMILY',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
      },
    ],
    waived: false,
    elected: false,
  },
  {
    id: 3,
    name: 'Hospital Indemnity',
    volumeBased: false,
    tiers: [
      {
        tier: 'EMPLOYEE',
        coverages: [],
        covers: ['EMPLOYEE'],
      },
      {
        tier: 'EMPLOYEE_SPOUSE',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE'],
      },
      {
        tier: 'EMPLOYEE_CHILD',
        coverages: [],
        covers: ['EMPLOYEE', 'CHILDREN'],
      },
      {
        tier: 'EMPLOYEE_FAMILY',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
      },
    ],
    waived: false,
    elected: false,
  },
  {
    id: 4,
    name: 'Vision',
    volumeBased: false,
    tiers: [
      {
        tier: 'EMPLOYEE',
        coverages: [],
        covers: ['EMPLOYEE'],
      },
      {
        tier: 'EMPLOYEE_SPOUSE',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE'],
      },
      {
        tier: 'EMPLOYEE_CHILD',
        coverages: [],
        covers: ['EMPLOYEE', 'CHILDREN'],
      },
      {
        tier: 'EMPLOYEE_FAMILY',
        coverages: [],
        covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
      },
    ],
    waived: false,
    elected: false,
  },
  // { id: 0, name: 'Accident', tier: '', waived: false, elected: false },
  // { id: 1, name: 'Cancer', tier: '', waived: false, elected: false },
  // { id: 2, name: 'Dental', tier: '', waived: false, elected: false },
  // {
  //   id: 0,
  //   name: '4 Tiered Dental',
  //   volumeBased: false,
  //   tiers: [
  //     {
  //       tier: 'EMPLOYEE',
  //       coverages: [],
  //       covers: ['EMPLOYEE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_SPOUSE',
  //       coverages: [],
  //       covers: ['EMPLOYEE', 'SPOUSE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_CHILD',
  //       coverages: [],
  //       covers: ['EMPLOYEE', 'CHILDREN'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_FAMILY',
  //       coverages: [],
  //       covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
  //     },
  //   ],
  //   waived: false,
  //   elected: false,
  // },
  // {
  //   id: 1,
  //   name: '2 Tier Vision',
  //   volumeBased: false,
  //   tiers: [
  //     {
  //       tier: 'EMPLOYEE',
  //       coverages: [],
  //       covers: ['EMPLOYEE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_FAMILY',
  //       coverages: [],
  //       covers: ['EMPLOYEE', 'SPOUSE', 'CHILDREN'],
  //     },
  //   ],
  //   rules: { isContrib: true, hasBuyUp: false },
  //   waived: false,
  //   elected: false,
  // },

  // {
  //   id: 2,
  //   name: 'Voluntary life and AD&D',
  //   volumeBased: true,
  //   plans: ['Choice1', 'Choice2'],
  //   tiers: [
  //     {
  //       tier: 'EMPLOYEE',
  //       coverages: [
  //         { name: 'Voluntary life', timesEarning: false, benefitAmounts: ['50000'] },
  //         { name: 'AS&D', timesEarning: false, benefitAmounts: ['50000'] },
  //       ],
  //       covers: ['EMPLOYEE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_SPOUSE',
  //       coverages: [
  //         { name: 'Voluntary life', timesEarning: false, benefitAmounts: ['5000', '10000', '15000'] },
  //         { name: 'AD&D', timesEarning: false, benefitAmounts: ['5000', '10000', '15000'] },
  //       ],
  //       covers: ['SPOUSE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_FAMILY',
  //       coverages: [
  //         { name: 'Voluntary life', timesEarning: false, benefitAmounts: ['5000', '10000', '15000'] },
  //         { name: 'AD&D', timesEarning: false, benefitAmounts: ['5000', '10000', '15000'] },
  //       ],
  //       covers: ['CHILDREN'],
  //     },
  //   ],
  //   rules: {
  //     isContrib: true,
  //     hasBuyUp: false,
  //     requirePreferredLanguage: false,
  //     requireDentalFacilityID: false,
  //     requireSSN: false,
  //     requireSmokerStatus: false,
  //   },
  //   waived: false,
  //   elected: false,
  // },
  // {
  //   id: 3,
  //   name: 'Critical illness',
  //   volumeBased: true,
  //   tiers: [
  //     {
  //       tier: 'EMPLOYEE',
  //       coverages: [{ name: 'Critical illness', timesEarning: false, benefitAmounts: ['50000'] }],
  //       covers: ['EMPLOYEE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_SPOUSE',
  //       coverages: [{ name: 'Critical illness', timesEarning: false, benefitAmounts: ['5000', '10000', '15000'] }],
  //       covers: ['SPOUSE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_FAMILY',
  //       coverages: [
  //         { name: 'Critical illness', timesEarning: true, benefitAmounts: ['1 x salary', '2 x salary', '3 x salary'] },
  //       ],
  //       covers: ['CHILDREN'],
  //     },
  //   ],
  //   rules: { isContrib: true, hasBuyUp: false },
  //   waived: false,
  //   elected: false,
  // },
  // {
  //   id: 4,
  //   name: 'Life',
  //   volumeBased: true,
  //   tiers: [
  //     {
  //       tier: 'EMPLOYEE',
  //       coverages: [{ name: 'Life', timesEarning: false, benefitAmounts: ['50000', '60000', '70000', '80000'] }],
  //       covers: ['EMPLOYEE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_SPOUSE',
  //       coverages: [
  //         {
  //           name: 'Life',
  //           timesEarning: true,
  //           benefitAmounts: ['1 X Salary', '2 X Salary', '3 X Salary', '4 X Salary', '5 X Salary'],
  //         },
  //       ],
  //       covers: ['SPOUSE'],
  //     },
  //     {
  //       tier: 'EMPLOYEE_FAMILY',
  //       coverages: [{ name: 'Life', timesEarning: true, benefitAmounts: ['1000000'] }],
  //       covers: ['CHILDREN'],
  //     },
  //   ],
  //   rules: { isContrib: true, hasBuyUp: false },
  //   waived: false,
  //   elected: false,
  // },
]

export const Benefits: FC = () => {
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { t } = useTranslation('translation', { keyPrefix: 'Benefits' })
  const { setStepHandler, setIsError, setErrorMsg } = useContext(AddMemberProgressContext)
  const [isViewingOptions, setIsViewingOptions] = useState(false)
  const noBenefitsError = t('NO_BENEFIT_ELECTED')

  const enrichedBenefits = benefitCategories.map((category) => {
    const benefit = enrollmentInfo.benefitInfo.find((b) => b.id === category.id)
    return {
      ...category,
      ...benefit,
    }
  })

  useEffect(() => {
    const onClickNext = async () => {
      const isValid =
        enrichedBenefits.length ===
        enrollmentInfo.benefitInfo.filter((benefit) => benefit.elected || benefit.waived).length
      if (!isValid) {
        setIsError(true)
        setErrorMsg(noBenefitsError)
      }
      if (isValid) {
        setIsError(false)
        setErrorMsg('')
      }
      return isValid
    }
    setStepHandler({ onClickNext })
  }, [enrollmentInfo, setStepHandler, setIsError, enrichedBenefits.length, setErrorMsg, noBenefitsError])

  // const { enrollmentInfo } = useContext(AddMemberContext)

  return (
    <>
      {benefitCategories.map((benefitCategory) => (
        <BenefitListCard
          key={benefitCategory.id}
          benefit={benefitCategory}
          isViewingOptions={isViewingOptions}
          setIsViewingOptions={setIsViewingOptions}
        />
      ))}
    </>
  )
}
