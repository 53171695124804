import { Dropdown } from '@component-library/helios'
import { type FC, useEffect, useState, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useGetMemberGroups } from '../../../api/hooks/useGetMemberGroups'
import { AddMemberContext } from '../../../context/AddMemberContext'

import * as styles from './Employment.module.css'

export const EligibleEmpGrp: FC = () => {
  const { control, getValues, setValue, trigger } = useFormContext()
  const { enrollmentInfo } = useContext(AddMemberContext)
  const { t } = useTranslation('translation', { keyPrefix: 'Employment' })
  const [selected, setSelected] = useState<string | undefined>()

  const { data: memberGroupResponse } = useGetMemberGroups()

  useEffect(() => {
    setSelected(enrollmentInfo?.employmentInfo.eligibleEmpGrp?.value)
  }, [getValues, enrollmentInfo])

  const onGrpChange = ({ option }: { index: number; option: { label: string; value: string } }) => {
    setSelected(option.value)
    setValue('eligibleEmpGrp', option)
    trigger('eligibleEmpGrp')
  }

  return (
    <>
      <div className={styles.textBoxMargin} data-testid="eligibleEmpGrp">
        <Controller
          rules={{
            required: {
              value: true,
              message: t('PLEASE_MAKE_SELECTION'),
            },
          }}
          control={control}
          name="eligibleEmpGrp"
          render={({ field, fieldState }) => {
            const { ...fields } = field
            return (
              <Dropdown
                {...fields}
                id={field.name}
                label={t('ELIGIBLE_EMP_GRP')}
                selected={selected}
                error={fieldState?.invalid}
                helperText={fieldState.error?.message}
                inputProps={undefined}
                inputRef={undefined}
                onChange={onGrpChange}
                options={memberGroupResponse?.memberGroups.map((memberGroup) => ({
                  label: memberGroup.name,
                  value: memberGroup.id,
                }))}
                placeholder=""
              />
            )
          }}
        />
      </div>
    </>
  )
}
