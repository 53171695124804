// GENERATED CODE, DO NOT TOUCH (from client/app/api/endpoints.tpl)
// This is automatically generated when files are modified in dev mode.

/* eslint-disable @typescript-eslint/no-unused-vars */

import type {
    Auth_AuthRequest,Auth_AuthResponse,

    Auth_LogoutRequest,Auth_LogoutResponse,

    Auth_ExtendRequest,Auth_ExtendResponse,
Health_HealthResponse,
Employer_MemberGroupResponse,

    Member_ListRequestQueryParams,Member_ListResponse,

    Member_GetByIDRequestPathParams,Member_GetByIDResponse,

    Member_GetDentalIDCardRequestPathParams,Member_GetDentalIDCardResponse,

    Member_AddRequest,Member_AddResponse,

    FeatureToggle_GetRequestPathParams,FeatureToggle_GetResponse,

    Transaction_GetByIDRequestPathParams,Transaction_GetByIDResponse,
} from 'types/domain'

import { createEndpointUtils } from "./axios";

export const endpoints = {
  Auth_Auth: createEndpointUtils<never, never, Auth_AuthRequest, Auth_AuthResponse
  >('POST', '/api/v1/auth/auth'),

  Auth_Logout: createEndpointUtils<never, never, Auth_LogoutRequest, Auth_LogoutResponse
  >('POST', '/api/v1/auth/logout'),

  Auth_Extend: createEndpointUtils<never, never, Auth_ExtendRequest, Auth_ExtendResponse
  >('POST', '/api/v1/auth/extend'),

  Health_Health: createEndpointUtils<never, never, never, Health_HealthResponse
  >('GET', '/api/v1/health/'),

  Employer_MemberGroup: createEndpointUtils<never, never, never, Employer_MemberGroupResponse
  >('GET', '/api/v1/employer/member-groups'),

  Member_List: createEndpointUtils<never, Member_ListRequestQueryParams, never, Member_ListResponse
  >('GET', '/api/v1/member/'),

  Member_GetByID: createEndpointUtils<Partial<Member_GetByIDRequestPathParams>, never, never, Member_GetByIDResponse
  >('GET', '/api/v1/member/{memberId}'),

  Member_GetDentalIDCard: createEndpointUtils<Partial<Member_GetDentalIDCardRequestPathParams>, never, never, Member_GetDentalIDCardResponse
  >('GET', '/api/v1/member/{memberId}/dental-id-card'),

  Member_Add: createEndpointUtils<never, never, Member_AddRequest, Member_AddResponse
  >('POST', '/api/v1/member/'),

  FeatureToggle_Get: createEndpointUtils<Partial<FeatureToggle_GetRequestPathParams>, never, never, FeatureToggle_GetResponse
  >('GET', '/api/v1/featuretoggle/{name}'),

  Transaction_GetByID: createEndpointUtils<Partial<Transaction_GetByIDRequestPathParams>, never, never, Transaction_GetByIDResponse
  >('GET', '/api/v1/transaction/{transaction_id}'),
} as const
