/* Do not change, this code is generated from Golang structs */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unused-modules */
/* eslint-disable prettier/prettier */

export enum SortBy {
    NameFull = "name_full",
    UpdatedAt = "compass_updated_at",
}
export enum BenefitStatus {
    APPROVED_ACTIVE = "APPROVED_ACTIVE",
    APPROVED_ACTIVE_SOON = "APPROVED_ACTIVE_SOON",
    TERMINATING_SOON = "TERMINATING_SOON",
}
export enum Language {
    English = "en",
    French = "fr",
    Spanish = "es",
    Estonian = "et",
    Farsi = "fa",
}
export enum Gender {
    UNKNOWN = "UNKNOWN",
    FEMALE = "FEMALE",
    MALE = "MALE",
    NOT_APPLICABLE = "NOT_APPLICABLE",
    UNIDENTIFIED = "UNIDENTIFIED",
}
export enum MemberSmoker {
    SMOKER_UNKNOWN = "SMOKER_UNKNOWN",
    NON_SMOKER = "NON_SMOKER",
    SMOKER = "SMOKER",
    NOT_APPLICABLE = "NOT_APPLICABLE",
}
export enum BenefitCategory {
    DENTAL = "DENTAL",
    VISION = "VISION",
    LIFE = "LIFE",
    DISABILITY = "DISABILITY",
    ACCIDENT = "ACCIDENT",
    CRITICAL_ILLNESS = "CRITICAL_ILLNESS",
    HOSPITAL_INDEMNITY = "HOSPITAL_INDEMNITY",
    CANCER = "CANCER",
    EAP = "EAP",
    LEAVE = "LEAVE",
}
export enum MemberStatusType {
    MEMBER_STATUS_UNKNOWN = "MEMBER_STATUS_UNKNOWN",
    ACTIVE = "ACTIVE",
    TERMINATED = "TERMINATED",
    TERMINATED_BALANCE_OF_BILLING = "TERMINATED_BALANCE_OF_BILLING",
}
export enum FeatureToggleName {
    MEMBER_MGMT_AUTH_ENABLED = "MEMBER_MGMT_AUTH_ENABLED",
    MEMBER_MGMT_TEST = "MEMBER_MGMT_TEST",
}
export enum DependentRelationship {
    SPOUSE = "SPOUSE",
    DEPENDENT = "DEPENDENT",
    UNKNOWN = "UNKNOWN",
}