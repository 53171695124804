import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

export const useGetMemberById = endpoints.Member_GetByID.createRequestHook(
  (request, { params, query }, { enabled }: { enabled: boolean }) => {
    return useQuery({
      queryKey: queryKeys.getMemberById({ ...params }),
      queryFn: () => request({ params }),
      enabled,
    })
  },
)
