.benefitCard {
  border: 2px solid #dcdedf;
  margin-top: 1.75rem;
  padding: 1.25rem;
  padding-right: 1.5rem;
}

.benefitCardHeader {
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  display: flex;
  width: 100%;
}

.benefitCardActions {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  vertical-align: middle;
  align-items: center;
  margin-left: auto;
}

.radioCaption {
  margin-left: 2.25rem;
  position: relative;
  top: -0.75rem;
  color: var(--Grey-Helios-Grey-700, #5b6062);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.radioCaption + div {
  padding-top: 0px;
}
