import { Breadcrumbs, Link, Heading, Icon } from '@component-library/helios'
import type { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Link as ReactRouterDomLink } from 'react-router-dom'

import { useGetMemberById } from '../../../api/hooks/useGetMemberById'
import { DepInfoCard } from '../../../components/DepInfoCard/DepInfoCard'
import { MemberBenefits } from '../../../components/MemberBenefits/MemberBenefits'
import { MemberInfoCard } from '../../../components/MemberInfoCard/MemberInfoCard'
import { PageContent } from '../../../components/PageContent/PageContent'
import { PolicyInfoCard } from '../../../components/PolicyInfoCard/PolicyInfoCard'
import { QueryResult } from '../../../components/QueryResult/QueryResult'
import { useAutoScrollTop } from '../../../hooks/useAutoScrollTop'
import { formatDateFullMonthWithYear } from '../../../utils/format'

import * as styles from './MemberDetail.module.css'

export const MemberDetail: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'MemberDetail' })
  useAutoScrollTop()
  const { memberId } = useParams()
  const getMemberById = useGetMemberById({ params: { memberId: memberId } }, { enabled: typeof memberId === 'string' })

  return (
    <>
      <QueryResult query={getMemberById}>
        {(data) => {
          const member = data?.member

          const memberName =
            member?.firstName + ' ' + member?.middleName + ' ' + member?.lastName + ' ' + member?.suffix
          const items: ComponentProps<typeof Breadcrumbs>['items'] = [
            {
              href: '/members',
              id: 'members',
              label: t('MEMBER_BREADCRUMB'),
            },
            {
              id: `${memberName ?? 'memberName'}`,
              label: `${memberName ?? ''}`,
            },
          ]

          return (
            <>
              <main>
                <PageContent>
                  <div className="d-none d-lg-block">
                    <Breadcrumbs
                      items={items}
                      className={styles.breadcrumbs}
                      itemRender={(props, index) => {
                        const isLast = index === items?.length - 1
                        return isLast ? undefined : (
                          <Link as={ReactRouterDomLink} to={props.href ?? '#'} size="sm">
                            {props.label}
                          </Link>
                        )
                      }}
                    />
                  </div>
                  <div className={`d-lg-none d-xl-none ${styles.marginTop1rem}`}>
                    <Link as={ReactRouterDomLink} to={'/members'} size="sm">
                      <Icon name="chevron-left" size="sm" className={styles.mobileIcon}></Icon>
                      {t('MOBILE_BACK_BTN')}
                    </Link>
                  </div>
                  <div className={styles.entityHeader}>
                    <Heading variant="1" as="h1">
                      {memberName}
                    </Heading>
                    <div className={styles.memberHeaderAttr}>
                      <div className={styles.cardLabel}>
                        {t('MEM_NUM_LABEL')}
                        {member?.externalId}
                      </div>
                      {member?.updatedAt && (
                        <div className={styles.cardLabel}>
                          {t('MEM_MODIFIED_LABEL')}
                          {formatDateFullMonthWithYear(new Date(member?.updatedAt))}
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>

                  <div className={styles.cardTitle}>{t('DETAIL_TITLE')}</div>
                  <div className={styles.cardMargin}>
                    <MemberInfoCard member={member} />
                  </div>
                  <div className={styles.cardTitle}>{t('BENEFITS_TITLE')}</div>
                  <MemberBenefits benefits={member.benefits} />
                  <div className={styles.cardTitle}>{t('DEP_TITLE')}</div>
                  <div className={styles.cardMargin}>
                    <DepInfoCard member={member} />
                  </div>
                  <div className={styles.cardTitle}>{t('POLICY_TITLE')}</div>
                  <div className={styles.cardMargin}>
                    <PolicyInfoCard member={member} />
                  </div>
                </PageContent>
              </main>
            </>
          )
        }}
      </QueryResult>
    </>
  )
}
